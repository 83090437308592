<template>
  <el-dialog
    class="custom-dialog"
    :title="info.condition_id ? '修改条件' : '新建条件'"
    :visible.sync="visible"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
  >
    <div style="margin-bottom: 30px">
      <el-form
        ref="form"
        :model="info"
        :rules="formRules"
        v-loading="getDetailLoading"
        hide-required-asterisk
        label-width="75px"
      >
        <el-form-item label="条件名称" prop="name">
          <el-input
            v-model="info.name"
            placeholder="请输入条件名称"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="批次数量" prop="count">
          <div class="line0">
            <div class="item">{{ `${info.content.length}个` }}</div>
            <div class="item right">
              <el-button
                type="primary"
                class="add"
                icon="el-icon-plus"
                @click="addPici"
                :disabled="info.disabled"
                >添加批次
              </el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="line" v-for="(item, key) in info.content">
        <div class="pici">批次{{ key + 1 }}</div>
        <div class="bili">
          执行比例
          <el-input-number
            :controls="false"
            :max="item.maxNum"
            :disabled="info.disabled"
            @focus="changeNum(key)"
            v-model="item.ratio"
            size="mini"
            step="1"
            step-strictly
            class="twofour"
          ></el-input-number>
          <span>%</span>
        </div>
        <div class="bili">
          执行间隔
          <el-input-number
            :controls="false"
            :min="0"
            size="mini"
            step="1"
            step-strictly
            :disabled="info.disabled"
            v-model="item.interval"
            class="twofour"
          ></el-input-number>
          <span>个月</span>
        </div>
        <div class="imgs" @click="deleteCon(key)" v-if="key !== 0">
          <img
            src="../../../assets/images/condition/icon_del_24@2x.png"
            alt=""
          />
        </div>
      </div>
      <div class="reds">
        批次1的“执行间隔”是相对“起算日期”，其他批次的“执行间隔”是相对于前一个批次的间隔。
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="befor"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api";
import dialogLife from "@/mixins/dialogLife";
import { deepClone } from "@/utils/core";

const defaultInfo = {
  name: "",
  content: [{ ratio: 0, interval: 0, maxNum: 100 }],
};

export default {
  mixins: [dialogLife],
  components: {},
  data() {
    return {
      infoBackup: deepClone(defaultInfo),
      info: deepClone(defaultInfo),
      formRules: {
        name: [
          { required: true, message: "请输入条件名称", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    addPici() {
      this.info.content.push({
        ratio: 0,
        interval: 0,
        maxNum: 100,
      });
    },

    deleteCon(e) {
      if (!this.info.disabled) {
        console.log(e);
        let data = this.info.content;
        data.splice(e, 1);
        this.info.content = data;
      }
    },

    // 判断最大最小
    changeNum(e) {
      // let i = 0;
      // this.info.content.map((value, key) => {
      //   if (key !== e) {
      //     i += value.ratio;
      //   }
      // });
      // this.info.content[e].maxNum = 100 - i;
    },

    async getDetail() {
      console.log(this.data);
      if (!this.data || !this.data.condition_id) return;
      this.getDetailLoading = true;
      let data = deepClone(this.data);
      this.info = data;
      this.info.content = Array.from(data.content, (v) => ({
        ...v,
        maxNum: 100,
      }));
      this.infoBackup = data;
      this.getDetailLoading = false;
    },

    befor() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { content } = this.info;
          let num = 0;
          for (let i in content) {
            num += content[i].ratio;
          }
          if (num != 100) {
            this.$message.error("所有批次执行比例的和应为100%");
          } else {
            this.submitMethod();
          }
        }
      });
    },

    async submitMethod() {
      const { name, content, condition_id, disabled } = this.info;
      if (condition_id && !disabled) {
        await api.condition.conditionEdit({
          name,
          content: JSON.stringify(content),
          condition_id,
        });
        this.submitLoading = false;
        this.handleClose();
        this.$emit("onSave");
        this.$message.success("提交成功");
      } else if (condition_id && disabled) {
        await api.condition.conditionEdit({
          name,
          condition_id,
        });
        this.submitLoading = false;
        this.handleClose();
        this.$emit("onSave");
        this.$message.success("提交成功");
      } else {
        await api.condition.conditionAdd({
          name,
          content: JSON.stringify(content),
        });
        this.handleClose();
        this.$emit("onSave");
        this.$message.success("提交成功");
      }
      this.info = deepClone(defaultInfo);
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.info = deepClone(defaultInfo);
      this.infoBackup = deepClone(defaultInfo);
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dialog {
  .el-dialog__footer {
    // padding: 0;
  }

  .line0 {
    display: flex;
    .item {
      width: 50%;
    }
    .right {
      text-align: right;
    }
  }

  .line {
    display: flex;
    height: 36px;
    background: #f5f7fa;
    line-height: 36px;
    padding: 0 8px;

    .pici {
      width: 15%;
    }
    .bili {
      display: flex;
      width: 35%;

      .twofour {
        margin: 5px 10px 0 5px;
      }

      .el-input-number {
        width: 40px;
        /deep/ .el-input__inner {
          height: 24px !important;
          padding: 0;
        }
      }
    }
    .imgs {
      img {
        width: 24px;
        height: 24px;
        margin-top: 6px;
        margin-left: 20px;
      }
    }
  }

  .reds {
    font-size: 12px;
    color: #ff4940;
    line-height: 18px;
    font-weight: 400;
    margin-top: 5px;
  }
}
</style>
