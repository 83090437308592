<template>
  <div
    class="empty flex-v flex-vc flex-hc"
    :style="`height:${height};width:${width}`"
  >
    <img
      class="nor_img"
      src="../../../assets/images/img_Nodata@2x.png"
      alt=""
    />
    <span class="tip_text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    // 文本内容
    text: {
      type: String,
      default: "暂无数据",
    },
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  .nor_img {
    width: 240px;
  }
  .tip_text {
    font-size: 18px;
    color: #898a8c;
    line-height: 28px;
    font-weight: 400;
    margin-top: 19px;
  }
}
</style>
