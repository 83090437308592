<template>
  <div class="participants2">
    <search-collapse title="" :senior="false" @handleSearch="getList">
      <template #search-left>
        <el-button
          type="primary"
          class="add"
          icon="el-icon-plus"
          @click="handleEdit"
          >新建</el-button
        >
      </template>
      <el-form class="el-form-custom" :inline="true" :model="queryCondition">
        <el-form-item label="">
          <el-input
            v-model="queryCondition.name"
            placeholder="请输入条件名称"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
    </search-collapse>

    <div class="row" ref="row" :style="`height:${rowHeight};`">
      <div class="col" v-if="showDataDemo">
        <div class="col-left" style="width: 100%">
          <el-table
            :data="tableData"
            highlight-current-row
            v-loading="tableLoading"
            @sort-change="changeSort"
            :row-class-name="tableRowClassName"
            ref="table"
            height="calc(100% - 64px)"
            class="custom_border"
          >
            <el-table-column
              prop="code"
              label="条件编号"
              min-width="150px"
              sortable="custom"
            />
            <!--          <el-table-column prop="name" label="条件名称" min-width="250px" >-->
            <!--            <template slot-scope="scope">-->
            <!--              <TextOverflow :limit="15" :text="scope.row.name || '- -'" />-->
            <!--            </template>-->
            <!--          </el-table-column>-->

            <af-table-column prop="name" label="条件名称" min-width="250px">
              <template slot-scope="scope">
                <chacter-overflow :limit="30" :text="scope.row.name || '- -'" />
              </template>
            </af-table-column>

            <af-table-column prop="number" label="批次数量" />
            <af-table-column prop="name" label="说明">
              <template slot-scope="scope">
                <TextOverflow
                  :limit="1"
                  :text="conditionShow(scope.row.content) || '- -'"
                />
                <!--              <div :title="titles(scope.row.content)" >-->
                <!--                {{-->
                <!--                  '批次1,执行比例'+ scope.row.content[0].ratio +'%,执行间隔'+ scope.row.content[0].interval +'个月...'-->
                <!--                }}-->
                <!--              </div>-->
              </template>
            </af-table-column>
            <!--          <el-table-column-->
            <!--              prop="status"-->
            <!--              label="状态"-->
            <!--              min-width="100px"-->
            <!--          >-->
            <!--            <template slot-scope="scope">-->
            <!--              {{-->
            <!--                scope.row.mode=='1'?'启用':'禁用'-->
            <!--              }}-->
            <!--            </template>-->
            <!--          </el-table-column>-->
            <el-table-column prop="id" label="操作" min-width="80px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleEdit(scope.$index, scope.row)"
                >
                  修改
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="ctime" label="创建时间" min-width="195px" />
            <af-table-column prop="creator" label="创建人" min-width="80px" />
            <el-table-column prop="mtime" label="修改时间" min-width="195px" />
            <af-table-column prop="modifier" label="修改人" min-width="80px" />
          </el-table>
          <!-- 分页 -->
          <pagination
            :total="total"
            :page-number.sync="queryCondition.page"
            :page-size.sync="queryCondition.limit"
            @pagination="getList"
          />
        </div>
      </div>
      <Empty v-if="!showDataDemo && requested" />
    </div>
    <Edit
      :data="relation_data"
      :visible="visible"
      :disable="relation_data.disabled"
      @onClose="handleClose"
      @onSave="handleSave"
    />
  </div>
</template>
<script>
import SearchCollapse from "@/components/base/search-collapse";
import Pagination from "@/components/base/pagination";
import tableLife from "@/mixins/tableLife.js";
import Edit from "./components/Edit.vue";
import api from "@/api";
import Empty from "@/components/base/empty";
import { displayTitle } from "@/utils/setting";
import TextOverflow from "@/components/base/text-overflow";
import { conditionShow } from "@/utils/core";

export default {
  mixins: [tableLife],
  components: {
    SearchCollapse,
    Pagination,
    Edit,
    Empty,
    TextOverflow,
  },
  data() {
    return {
      conditionShow,
      editableTabsValue: "0",
      defaultSortWord: "code",
      total: 0,
      queryCondition: {
        name: "",
        page: 1,
        limit: 20,
      },
      visible: false,
      import_visible: false,
      slideData: {},
      option_data: {},
      stock_data: {},
      relation_data: {
        disabled: false,
      },
      statusData: [{}],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getApiTableData() {
      // const data = await api.condition.conditionList(this.queryCondition);
      // this.slideData = data.list[0];
      // console.log(data);
      // this.rowClick(this.slideData);
      // return data;
      return api.condition.conditionList(this.queryCondition);
    },
    handleEdit(index, row) {
      console.log(row);
      this.relation_data = row ? row : {};
      if (this.relation_data.condition_id) {
        this.getConditionCheckStatus(row.condition_id);
      } else {
        this.visible = true;
      }
    },

    /** 2022/3/22
     *作者:Barney Jiang
     *功能:获取可编辑状态
     */
    async getConditionCheckStatus(e) {
      const data = await api.condition.getConditionCheckStatus({
        condition_id: e,
      });
      console.log(data.is_ref);
      if (data.is_ref > 0) {
        this.relation_data.disabled = true;
      } else {
        this.relation_data.disabled = false;
      }
      this.visible = true;
    },

    handleImport() {
      this.import_visible = true;
    },
    handleDel(index, row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.condition.conditionDel({ condition_id: row.condition_id });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getList();
        })
        .catch(() => {});
    },
    // 保存
    handleSave() {
      this.handleClose();
      this.getList();
    },
    // 关闭编辑模态窗
    handleClose() {
      this.visible = false;
      this.import_visible = false;
    },

    titles(e) {
      return displayTitle(e);
    },
  },
};
</script>
<style lang="scss" scoped>
.participants2 {
  height: 100%;
  .el-form-custom {
    text-align: right;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .export {
    padding-left: 12px;
    .img_icon {
      width: 20px;
      margin-right: 8px;
    }
  }

  .col-right {
    overflow: scroll;
  }
}
</style>
