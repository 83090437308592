/** 2022/3/22
 *作者:Barney Jiang
 *功能:数字逗号分割
 */
export const divisionNum = (nums) => {
    let num = (nums || 0).toString(), result = '';
    while (num.length > 3) {
        result = ',' + num.slice(-3) + result;
        num = num.slice(0, num.length - 3);
    }
    if (num) {
        result = num + result;
    }
    return result;
}

/** 2022/3/22
 *作者:Barney Jiang
 *功能:鼠标经过显示全部文字
 */

export const displayTitle = (datas) => {
    let data = datas
    let result=''
    for(let i in data){
        result += ('批次'+ (parseFloat(i)+1) +',执行比例'+ data[i].ratio +'%,执行间隔'+ data[i].interval +'个月;')
    }
    return result
}